<template>
  <div class="pre-body pre-body-sm">
    <img class="pre-icon" src="/assets/images/login.png" alt="" />

    <div class="pre-divider"></div>
    <h3>Login</h3>

    <error-alert v-if="loginError"> Invalid username or password </error-alert>
    <b-form-group label="Email">
      <pnw-input
        v-model="form.email"
        :state="isValidEmail"
        type="email"
        placeholder="name@email.com"
        autocomplete="username"
      ></pnw-input>
      <b-form-invalid-feedback> Enter a valid email </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Password">
      <template slot="label">
        <div class="d-flex justify-content-between">
          <label>Password</label>
        </div>
      </template>
      <pnw-input
        v-model="form.password"
        type="password"
        placeholder="your password"
        autocomplete="current-password"
        @keyup.enter="login"
      ></pnw-input>
    </b-form-group>

    <div class="pre-pad"></div>

    <div class="pre-divider"></div>

    <div class="pre-pad"></div>

    <div class="pre-form--group">
      <div class="pre-btns centered">
        <pnw-button
          @click="login"
          size="xl"
          variant="primary"
          :disabled="!canContinue"
        >
          log in</pnw-button
        >
      </div>
      <div class="pre-btns centered">
        <router-link
          :to="{ name: $routerItems.FORGOT_PASSWORD }"
          class="pre-btn pre-btn-underline"
        >
          <div class="pre-btn--copy">Forgot Password?</div>
        </router-link>
        <router-link
          :to="{ name: $routerItems.REGISTER }"
          class="pre-btn pre-btn-underline"
        >
          <div class="pre-btn--copy">Create an account</div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import validations from "../../utils/validations";
import auth from "../../services/AuthService";
import ErrorAlert from "../../components/ErrorAlert.vue";
import { mapGetters } from "vuex";
import PnwButton from "../../components/PnwButton.vue";
import PnwInput from "../../components/PnwInput.vue";
import playSound from "../../services/AudioService";

export default {
  name: "Login",
  components: {
    ErrorAlert,
    PnwButton,
    PnwInput,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      checking: false,
      isValidEmail: null,
      loginError: null,
    };
  },
  methods: {
    async login() {
      if (!this.canContinue) {
        return;
      }
      this.checking = true;
      this.loginError = null;
      try {
        const { user } = await auth.login(this.form);
        if (!user) {
          this.$router.push({
            name: this.$routerItems.REGISTER,
            query: this.form,
          });
        } else {
          const curProfile = user.profiles.find(
            (item) => item.id === user.currentProfileId
          );
          if (curProfile?.ticketCode) {
            this.$router.push({ name: this.$routerItems.PROFILE });
          } else {
            this.$router.push({
              name: this.$routerItems.TICKET_CODE,
            });
          }
        }
      } catch (err) {
        playSound("GF-SpiritNet-Error.wav");
        this.loginError = err.response.status;
      }
      this.checking = false;
    },
  },
  watch: {
    "form.email": function (newValue) {
      this.isValidEmail = validations.isValidEmail(newValue);
    },
  },
  computed: {
    ...mapGetters({
      currentProfile: "currentProfile",
    }),
    canContinue() {
      return this.form.email && this.form.password && !this.checking;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/colors";
.auth-form {
  max-width: 600px;
  .forgot-password {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    line-height: 1;
    vertical-align: top;
    margin-bottom: 8px;
    padding-bottom: 0;
    color: $accent-02;
    text-shadow: 2px 0 0 blue, -1px 0 0 red;
  }
}
</style>
